import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Types
import { SocialLinksProps } from '../../types';

// Components
import NewsletterModal from '../newsletter-modal/NewsletterModal';

// CSS
import css from './Header.module.scss';

const Header: FC = (): ReactElement => {
	const { t } = useTranslation('translation');
	const [socialLinksExpanded, setSocialLinksExpanded] = useState(false);
	const socialToggleLabel = t(`socialLinks.menuToggle.${!socialLinksExpanded ? 'expand' : 'collapse'}`);

	useEffect(() => {
		document.querySelector('html')?.classList[socialLinksExpanded ? 'add' : 'remove']('mobile-menu-opened');

		return () => {
			document.querySelector('html')?.classList.remove('mobile-menu-opened');
		};
	}, [socialLinksExpanded]);

	return (
		<header className={css.Header}>
			<div className={css.Wrapper}>
				<h1 className={css.Logo} title={String(t('appName'))}>
					{t('appName')}
				</h1>
				<div className={css.SocialLinks} data-expanded={socialLinksExpanded || undefined}>
					<button
						type="button"
						className={css.SocialLinksToggle}
						title={socialToggleLabel}
						onClick={() => {
							setSocialLinksExpanded(!socialLinksExpanded);
						}}
					>
						<span>{socialToggleLabel}</span>
					</button>
					<div className={css.SocialLinksPanel}>
						<p>{t('socialLinks.title')}</p>
						<ul>
							{(t('socialLinks.links', { returnObjects: true }) as Array<SocialLinksProps>).map(
								(link: SocialLinksProps): ReactElement => {
									return (
										<li key={link.id} data-id={link.id}>
											<a
												href={link.url}
												title={link.label}
												rel={link.external ? 'noreferrer' : undefined}
												target={link.external ? '_blank' : undefined}
												data-id={link.id}
											>
												{link.label}
											</a>
										</li>
									);
								}
							)}
							<li data-id="infolettre">
								<NewsletterModal />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
