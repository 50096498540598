import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Types
import { FormFieldProps } from '../../types';

// CSS
import css from './NewsletterModal.module.scss';

const NewsletterModal = () => {
	const { t } = useTranslation('translation');
	const [dialogOpened, setDialogOpened] = useState(false);
	const dialogRef = useRef<HTMLDialogElement>(null);

	useEffect(() => {
		const modal = dialogRef.current;

		if (dialogOpened) {
			document.documentElement.classList.add(css.documentModalOpened);
			modal?.showModal();
		} else {
			document.documentElement.classList.remove(css.documentModalOpened);
			modal?.close();
		}
	}, [dialogOpened]);

	const closeModalHandler = () => {
		setDialogOpened(false);
	};

	const formSubmitHandler = () => {
		setDialogOpened(false);
	};

	return (
		<>
			<button
				type="button"
				title={t('newsletter.modalButtonLabel') || ''}
				onClick={() => {
					setDialogOpened(true);
				}}
			>
				{t('newsletter.modalButtonLabel')}
			</button>
			<dialog ref={dialogRef} className={css.dialog} onClose={closeModalHandler}>
				<div className={css.window}>
					<form action={t('newsletter.formAction') || ''} onSubmit={formSubmitHandler} method="post" target="_blank">
						<div className={css.header}>
							<h2 className={css.title}>{t('newsletter.modalTitle')}</h2>

							<button
								type="button"
								title={t('close') || ''}
								className={css.closeModalButton}
								onClick={closeModalHandler}
							>
								{t('close')}
							</button>
						</div>

						<fieldset className={css.fieldset}>
							{(t('newsletter.fields', { returnObjects: true }) as FormFieldProps[]).map((field, index) => {
								const { label, required, ...attr } = field;
								return (
									<label key={index} className={css.field}>
										<span className={css.label}>
											{field.label}
											{required && <span className={css.mandatory}>*</span>}
										</span>
										<input {...attr} required={required} className={css.input} />
									</label>
								);
							})}
						</fieldset>

						<div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
							{/* real people should not fill this in and expect good things - do not remove this or risk form bot
									signups */}
							<input type="text" name="b_de84267eeea8c43d422fd271a_1d5eb1b5b7" tabIndex={-1} />
						</div>

						<div className={css.actions}>
							<button type="submit" name="subscribe">
								{t('newsletter.submitButtonLabel')}
							</button>
						</div>
					</form>
				</div>
			</dialog>
		</>
	);
};

export default NewsletterModal;
